import { UseBaseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { store } from "../core/store/ConfigStore";

export const GetCustomerProduct = async (params: UseBaseQueryOptions) => {
  const customerName = params.queryKey && params.queryKey[1];
  const customerId = params.queryKey && params.queryKey[2];
  const token = store.getState().Auth.Token;
  const config = {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  };
  const { data } = await axios.get(
    `${process.env.REACT_APP_URL}/Customer/portal/customerproductportal/${customerName}/${customerId}`,
    { headers: config }
  );
  return data.data;
};
