import React, { useEffect, useRef } from "react";
import { bindActionCreators, compose } from "redux";

import ReportComponent from "../../../components/Content/Report/ReportComponent";
import { connect } from "react-redux";
import moment from "moment";
import { GetCustomerFeature } from "../../../service/authService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCustomerProduct } from "../../../service/customerService";
import {
  GetReportType,
  mutateDownloadReport,
} from "../../../service/reportService";
import { ResponseDownload } from "../../../core/utils/helper";
import * as ActionLogin from "../../../core/action/Login";
import { message } from "antd";

interface IProps {
  customerId: any;
  customerName: any;
  actionLogin: any;
}
const ReportContainer = (props: IProps) => {
  const { customerId, customerName, actionLogin } = props;
  const formRef = useRef<any>(null);

  const { data: dataCustomerFeature } = useQuery(
    ["getCustomerFeature", customerId],
    GetCustomerFeature
  );
  const { data: dataCustomerProduct, isLoading: isLoadingCustomerProduct } =
    useQuery(
      ["getDataCustomerProduct", customerName, customerId],
      GetCustomerProduct
    );
  const { data: dataReportType, isLoading: isLoadingReportType } = useQuery(
    ["getDataReportType", customerId],
    GetReportType
  );
  const mutateDownloadReports = useMutation(mutateDownloadReport, {
    onSuccess: ({ data }: any, variable: any) => {
      const { cycleDateFrom, cycleDateTo, customReportFileId } = variable;
      const selectedFormat =
        dataReportType
          .find((value: any) => value.id === customReportFileId)
          ?.format?.toLowerCase() || "xlsx";
      const fileName = `Laporan ${cycleDateFrom}-${cycleDateTo}.${
        selectedFormat === "excel" ? "xlsx" : selectedFormat
      }`;
      ResponseDownload(data, fileName);
    },
    onError: (error: any) => {
      if (error.response.status === 401) {
        // Todo remove redux usage
        actionLogin.submitTokenRefresh();
      } else {
        const key = "download";
        if (error.message === "Network Error") {
          message.error({
            content: "Gagal Di Download",
            key,
            duration: 3,
          });
        } else if (
          error.response.data.Message === null ||
          error.response.data.Message === undefined
        ) {
          message.warning({
            content: "Laporan Tidak Tersedia",
            key,
            duration: 3,
          });
        } else {
          message.error({
            content: "Gagal Di Download",
            key,
            duration: 3,
          });
        }
      }
    },
  });

  const handleDownload = (values: any) => {
    const {
      datePeriode,
      customerProduct,
      reportType,
      statusDelivery,
      filterDateType,
      checkboxData,
    } = values;

    const payload = {
      cycleDateFrom: moment(datePeriode[0]).format("YYYYMMDD"),
      cycleDateTo: moment(datePeriode[1]).format("YYYYMMDD"),
      customerId,
      customerProducts: customerProduct,
      customReportFileId: reportType,
      deliveryStats: statusDelivery,
      username: customerName,
      filterDateType: filterDateType,
      deliveryDetail: checkboxData,
      deliveryType: ["reguler"],
      fileString: "",
      groupFileName: false,
      today: true,
      month: false,
      trueOrFalse: false,
    };
    mutateDownloadReports.mutate(payload);
  };

  useEffect(() => {
    const customerProductIds =
      dataCustomerProduct && dataCustomerProduct.map((data: any) => data.id);
    formRef.current.form.change("customerProduct", customerProductIds);
  }, [dataCustomerProduct]);

  const listCustomerProduct =
    dataCustomerProduct &&
    dataCustomerProduct.map((data: any) => {
      return {
        id: data.id,
        name: data.name,
      };
    });
  const listReportType =
    dataReportType &&
    dataReportType.map((data: any) => {
      return {
        id: data.id,
        name: data.name,
      };
    });

  return (
    <ReportComponent
      isLoading={mutateDownloadReports.isLoading}
      formRef={formRef}
      listCustomerProduct={listCustomerProduct}
      listReportType={listReportType}
      searchMonthFilter={dataCustomerFeature?.searchMonthFilter}
      handleDownload={handleDownload}
      isLoadingReportType={isLoadingReportType}
      isLoadingCustomerProduct={isLoadingCustomerProduct}
    />
  );
};

const mapStateToProps = (state: any) => ({
  customerId: state.Auth.CustomerId,
  customerName: state.Auth.UsernameAuth,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLogin: bindActionCreators(ActionLogin, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ReportContainer);
