import { UseBaseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { store } from "../core/store/ConfigStore";
import { message, Modal } from "antd";

export const GetReportType = async (params: UseBaseQueryOptions) => {
  const customerId = params.queryKey && params.queryKey[1];
  const token = store.getState().Auth.Token;
  const config = {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  };
  const { data } = await axios.get(
    `${process.env.REACT_APP_URL}/Setting/SelectCustomReportFilePortal/${customerId}`,
    { headers: config }
  );
  return data.data;
};

export const mutateDownloadReport = async (params: any) => {
  const token = store.getState().Auth.Token;

  const config = {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  };
  return await axios.post(
    `${process.env.REACT_APP_URL}/Delivery/DownloadReportCustomer`,
    params,
    {
      headers: config,
      responseType: "blob",
      onDownloadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const key = "download";
        let percentProgress = Math.floor((loaded * 100) / total);

        if (percentProgress < 100) {
          message.loading({
            content: "Loading " + percentProgress + "%",
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: "Download Berhasil",
            key,
            duration: 1.5,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: "Download Gagal",
            key,
            duration: 3,
          });
        }
      },
    }
  );
};
