import React from "react";
import { Form, Select } from "antd";
import styles from "./SelectField.module.css";
const { Option } = Select;

type SelectFieldProps = {
  validate?: boolean;
  allowClear?: boolean;
  validateStatus?:
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating"
    | undefined;
  errorMessage?: string;
  label?: any;
  placeholder?: string;
  style?: any;
  value?: any;
  data?: Array<any>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: () => void;
  disabled?: boolean;
  mode?: "multiple" | "tags" | undefined;
  loading?: boolean;
  isNotShowAll?: boolean;
  labelKey?: string;
  valueKey?: string;
  secondLabelKey?: string;
  additionalOnChange?: any;
};

export default function SelectField({
  validate = false,
  allowClear = false,
  loading = false,
  validateStatus = undefined,
  disabled = false,
  isNotShowAll = false,
  errorMessage,
  placeholder,
  label,
  style,
  value = undefined,
  data,
  onChange = () => undefined,
  onBlur = () => undefined,
  onFocus = () => undefined,
  mode,
  labelKey = "name",
  valueKey = "id",
  secondLabelKey,
  additionalOnChange,
}: SelectFieldProps) {
  let dataAll = [] as any;
  if (
    !isNotShowAll &&
    mode === "multiple" &&
    value?.length === (data?.length || 0) - 1
  ) {
    dataAll = data?.filter((value) => value[valueKey] !== 0);
  } else if (
    isNotShowAll &&
    mode === "multiple" &&
    value.length !== 0 &&
    value?.includes(0)
  ) {
    dataAll = data?.filter((value) => value[valueKey] === 0);
  } else {
    dataAll = data;
  }

  const handleOnChange = (selected: any) => {
    if (mode === "multiple") {
      if (
        value?.includes(0) &&
        selected.length === (data?.length || 0) - 1 &&
        !selected.includes(0)
      ) {
        onChange([]);
        return;
      }

      if (selected.includes(0) && !value?.includes(0)) {
        const arrIds = [] as Array<any>;

        data?.forEach((product) => {
          arrIds.push(product[valueKey]);
        });

        onChange(arrIds.filter((selected) => selected !== 0));
        return;
      }
    }

    onChange(selected);

    if (additionalOnChange) {
      additionalOnChange(selected);
    }
  };

  return (
    <div>
      {label && (
        <span>
          <b>{label}</b>
        </span>
      )}
      <Form.Item
        validateStatus={validate ? validateStatus : undefined}
        help={validate && errorMessage}
        required
        style={{ marginBottom: 0 }}
      >
        <Select
          placeholder={placeholder}
          style={style}
          disabled={disabled}
          showSearch
          optionFilterProp="children"
          value={value || undefined}
          onChange={handleOnChange}
          onBlur={onBlur}
          className={styles.selectField}
          mode={mode}
          allowClear={allowClear}
          onFocus={onFocus}
          loading={loading}
        >
          {dataAll?.map((item: any, index: any) => (
            <Option
              key={index}
              value={item[valueKey]}
              disabled={item?.disabled || false}
            >
              {item[labelKey]} {secondLabelKey && `(${item[secondLabelKey]})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}
