/* eslint-disable jsx-a11y/anchor-is-valid */

import "moment/locale/id";
import "../../../index.css";
import "../../../styles/ReportStyle/index.css";

import { Checkbox, Col, Form as FormAntd, Row, Select } from "antd";
import React from "react";

import ButtonComponent from "../../../materials/ButtonMaterial";
import SpinMaterial from "../../../materials/SpinMaterial";
import moment from "moment";
import { Field, Form } from "react-final-form";
import InputDatePicker from "../../../materials/InputDatePicker";
import SelectField from "../../../materials/SelectField";

const { Option } = Select;

interface IProps {
  isLoading: boolean;
  formRef: any;
  listCustomerProduct: any[];
  listReportType: any[];
  searchMonthFilter: number;
  handleDownload: (values: any) => void;
  isLoadingReportType: boolean;
  isLoadingCustomerProduct: boolean;
}

export default function ReportComponent(props: IProps) {
  const {
    isLoading,
    handleDownload,
    formRef,
    listCustomerProduct,
    listReportType,
    searchMonthFilter,
    isLoadingReportType,
    isLoadingCustomerProduct,
  } = props;

  const initial = React.useMemo(() => {
    return {
      filterDateType: "Cycle",
      datePeriode: [null, null],
      statusDelivery: ["Return", "Success", "OnProcces"],
      reportType: 0,
      checkboxData: true,
      customerProduct: listCustomerProduct
        ? [...listCustomerProduct.map((item: any) => item.id)]
        : [],
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <SpinMaterial spinning={isLoading} size={"large"}>
        <div className="titleLayoutReport">
          <span className="titleReport">
            <b>LAPORAN PELANGGAN</b>
          </span>
        </div>
        <Form
          onSubmit={() => {}}
          initialValues={initial}
          validate={(values: any) => {
            const { datePeriode, statusDelivery, customerProduct } = values;
            const errors: any = {};

            if (!datePeriode || !datePeriode[0] || !datePeriode[1]) {
              errors.datePeriode = "Tanggal wajib diisi";
            }

            if (statusDelivery && statusDelivery.length === 0) {
              errors.statusDelivery = "Status pengiriman wajib diisi";
            }

            if (customerProduct && customerProduct.length === 0) {
              errors.customerProduct = "Produk wajib diisi";
            }

            return errors;
          }}
        >
          {(formProps) => {
            const { values, invalid, form } = formProps;
            formRef.current = formProps;
            return (
              <div>
                <Row>
                  <Col span={12} className="paddingReportLeft">
                    <span>
                      <b>Status Pengiriman</b>
                    </span>
                    <Field name="datePeriode">
                      {({ input, meta }) => {
                        return (
                          <InputDatePicker
                            firstPlaceholder="Dari bulan"
                            secondPlaceholder="Sampai bulan"
                            type="range"
                            format="DD MMMM YYYY"
                            value={input.value}
                            onChange={(value) => input.onChange(value)}
                            onCalendarChange={(value) => input.onChange(value)}
                            disabledDate={(current: any) => {
                              const maxBackdate = moment().subtract(
                                searchMonthFilter,
                                "months"
                              );
                              const maxRange = 31;

                              // Disable dates that are earlier than 3 months ago
                              if (
                                current &&
                                current < maxBackdate.startOf("day")
                              ) {
                                return true;
                              }

                              // Disable dates after 31 days from the start date
                              if (values?.datePeriode && current) {
                                const tooLate =
                                  values.datePeriode[0] &&
                                  current.diff(values.datePeriode[0], "days") >
                                    maxRange - 1;
                                return tooLate;
                              }

                              return false;
                            }}
                            addonBefore={
                              <Field name="filterDateType">
                                {({ input }) => (
                                  <SelectField
                                    data={[
                                      {
                                        value: "Process",
                                        label: "Pickup",
                                      },
                                      {
                                        value: "Cycle",
                                        label: "Cycle",
                                      },
                                    ]}
                                    value={input.value}
                                    onChange={(value) => input.onChange(value)}
                                    style={{ width: 100 }}
                                    labelKey="label"
                                    valueKey="value"
                                  />
                                )}
                              </Field>
                            }
                            onBlur={input.onBlur}
                            error={meta.error}
                          />
                        );
                      }}
                    </Field>
                  </Col>
                  <Col span={12} className="paddingReportRight">
                    <Field name="statusDelivery">
                      {({ input, meta }) => (
                        <SelectField
                          label="Status Pengiriman"
                          placeholder="Status Pengiriman"
                          value={input.value}
                          mode="multiple"
                          data={[
                            { id: "Success", name: "Diterima" },
                            { id: "Return", name: "Dikembalikan" },
                            { id: "OnProcces", name: "Proses" },
                          ]}
                          onBlur={input.onBlur}
                          onChange={(value) => input.onChange(value)}
                          validateStatus={meta.error ? "error" : "validating"}
                          errorMessage={meta.error}
                          validate={meta.error}
                          allowClear
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="paddingReportLeft">
                    <div className="borderTop">
                      <Field
                        name="customerProduct"
                        render={({ input, meta }) => (
                          <SelectField
                            label="Produk"
                            placeholder="Pilih Produk"
                            mode="multiple"
                            value={input.value}
                            onChange={input.onChange}
                            additionalOnChange={(values: any) => {
                              if (values && values.includes("SetAllProduct")) {
                                const allIds = listCustomerProduct?.map(
                                  (data: any) => data.id
                                );
                                return input.onChange(allIds);
                              }
                            }}
                            onBlur={input.onBlur}
                            loading={isLoadingCustomerProduct}
                            allowClear
                            validateStatus={meta.error ? "error" : "validating"}
                            errorMessage={meta.error}
                            validate={meta.error}
                            data={[
                              ...(Array.isArray(listCustomerProduct) &&
                              listCustomerProduct?.length === input.value.length
                                ? []
                                : listCustomerProduct?.length !== 0
                                ? [
                                    {
                                      key: "SetAllProduct",
                                      id: "SetAllProduct",
                                      name: "Pilih Semua Produk",
                                    },
                                  ]
                                : []),
                              ...(Array.isArray(listCustomerProduct)
                                ? listCustomerProduct
                                : []),
                            ]}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={12} className="paddingReportRight">
                    <div className="borderTop">
                      <span>
                        <b>Jenis Laporan</b>
                      </span>
                      <Field
                        name="reportType"
                        render={({ input, meta }) => (
                          <FormAntd.Item
                            validateStatus={
                              meta.error && meta.touched ? "error" : ""
                            }
                            help={meta.error && meta.touched && meta.error}
                          >
                            <Select
                              className="widthFix"
                              loading={isLoadingReportType}
                              value={
                                input.value === 0 ? "Standar" : input.value
                              }
                              placeholder="Pilih Jenis Laporan"
                              showSearch
                              onBlur={input.onBlur}
                              onChange={input.onChange}
                            >
                              {listReportType?.length === 0 ? null : (
                                <Option key={""} value={0}>
                                  {"Standar"}
                                </Option>
                              )}

                              {listReportType?.map((data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              ))}
                            </Select>
                          </FormAntd.Item>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <Field
                      name="checkboxData"
                      type="checkbox"
                      render={({ input }) => (
                        <Checkbox
                          {...input}
                          className="checkboxReport"
                          checked={input.checked}
                        >
                          Data Lengkap
                        </Checkbox>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="">
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      paddingRight: "1rem",
                    }}
                  >
                    <ButtonComponent
                      disabled={invalid}
                      onClick={() => form.reset()}
                      type="primary"
                      shape="round"
                      size="middle"
                      className={
                        invalid
                          ? "btnDisableResetReport"
                          : "btnEnableResetReport"
                      }
                    >
                      <b>Reset</b>
                    </ButtonComponent>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: "1rem",
                    }}
                  >
                    <ButtonComponent
                      disabled={invalid}
                      onClick={() => handleDownload(values)}
                      type={"primary"}
                      shape="round"
                      size={"middle"}
                      className="btnDownloadReport"
                    >
                      <b>Download</b>
                    </ButtonComponent>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form>
      </SpinMaterial>
    </React.Fragment>
  );
}
