import React from "react";
import styles from "./InputDate.module.css";
import locale from "antd/es/date-picker/locale/id_ID";
import { DatePicker, Form } from "antd";

type InputDateProps = {
  label: any;
  placeholder: string;
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  error?: string;
};

export default function InputDate({
  label,
  placeholder,
  error,
  onChange = () => undefined,
  onBlur = () => undefined,
  value,
}: InputDateProps) {
  return (
    <div className={styles.wrapperField}>
      <p className={styles.labelField}>{label}</p>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={error ? "error" : undefined}
      >
        <DatePicker
          className="datepickerDefault"
          locale={locale}
          placeholder={placeholder}
          style={{ borderRadius: 8 }}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          value={value}
        />
      </Form.Item>
      {error && <p className={styles.labelError}>{error}</p>}
    </div>
  );
}
